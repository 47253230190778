/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, Button, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column style={{"paddingTop":109,"paddingBottom":88}} name={"sluzby"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={""} animS={"3"} style={{"marginBottom":0,"paddingBottom":60}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2" style={{"maxWidth":"","paddingBottom":5,"paddingTop":0}} anim={"2"}>
              
              <Title className="title-box lh--14" style={{"marginBottom":25}} content={"<span style=\"font-weight: bold;\">Služby</span>&nbsp;na nejvyšší úrovni&nbsp;<br>"}>
              </Title>

              <Text className="text-box fs--14" style={{"maxWidth":826,"marginTop":0}} content={"věříme, že maličkosti a detaily tvoří rozdíl mezi dobrou službou a perfektní. Proto dbáme na všechny aspekty, které mohou pomoci při prodeji vaší nemovitosti, jako je vlastní web, brožury k nemovitostem, 3D půdorys a mnoho dalšího. Máme svůj standard služeb, ze kterého nechceme ustoupit, a ke každému obchodu přistupujeme s maximální péčí, ať se jedná o prodej nemovitosti za 2 nebo 20 milionů. Pokud jste se rozhodli řešit prodej nemovitosti, s námi dostanete maximálně profesionální službu s důrazem na každý detail."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--stretch" style={{"maxWidth":""}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/dda6fb48e6f54bbfa33d2576b2d82738_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/dda6fb48e6f54bbfa33d2576b2d82738_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/dda6fb48e6f54bbfa33d2576b2d82738_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/dda6fb48e6f54bbfa33d2576b2d82738_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/nemovistinaprodej\">Odborné nacenění nemovitosti</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Upřímné a odborné poradenství v oblasti nacenění nemovitosti vám ušetří čas a zajistí optimální cenu v co nejkratším čase.&nbsp;&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/19c5b953e2234f7f893f973cfae03294_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/19c5b953e2234f7f893f973cfae03294_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/19c5b953e2234f7f893f973cfae03294_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/19c5b953e2234f7f893f973cfae03294_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-pronajmout\">Právní služby</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Prodej či nákup nemovitosti je životní rozhodnutí. Většina lidí prodává nemovitost jednou nebo dvakrát za život, což je důvod, proč je profesionální právní servis tak důležitý. Pomůžeme vám projít celým procesem bez rizika a stresu.\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/efb611ae5d30420db83b75d1302c7932_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/efb611ae5d30420db83b75d1302c7932_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/efb611ae5d30420db83b75d1302c7932_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/efb611ae5d30420db83b75d1302c7932_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/odhad-nemovitosti\">Profesionální prezentace nemovitosti</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--14" style={{"marginTop":12}} content={"Dbáme na maximální pozornost při prezentaci vaší nemovitosti. Profesionální fotografie, kvalitní video, záběry z dronu a celkové poradenství v oblasti přípravy nemovitosti na prezentaci jsou u nás standardem, ať prodáváte byt za 2 miliony nebo vilu za 20 milionů.\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/d70b4816793844359b691d754f7cdea8_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/d70b4816793844359b691d754f7cdea8_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/d70b4816793844359b691d754f7cdea8_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/d70b4816793844359b691d754f7cdea8_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Vlastní web nemovitosti</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Každá naše nemovitost má vlastní webovou stránku, kde jsou všechny potřebné informace přehledně a stručně strukturované tak, aby vaše rozhodování při koupi bylo co nejlepší.\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/ca9fd0add1304fcc90dc9135b2436485_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/ca9fd0add1304fcc90dc9135b2436485_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/ca9fd0add1304fcc90dc9135b2436485_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/ca9fd0add1304fcc90dc9135b2436485_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Úvěrová praxe\n\n</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Máme mnohaletou praxi ve zprostředkování úvěrových produktů, především hypotečních, a to jak pro české občany, tak občany jiných států. Naše zkušenosti nám umožňují posoudit, zda má kupující šanci na získání financování, což opět šetří váš čas. Pokud úvěr zprostředkováváme my, máme celý proces pod kontrolou a dokážeme pružně reagovat na situace, které mohou nastat, a předcházet problémům a prostojům.\n\n\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/76bc22cfe3b6429caff9d0ab9b554caa_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/76bc22cfe3b6429caff9d0ab9b554caa_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/76bc22cfe3b6429caff9d0ab9b554caa_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/76bc22cfe3b6429caff9d0ab9b554caa_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Brožury k prodávané nemovitosti a 3D půdorys</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Víme, že pečlivá příprava na prohlídky se zájemci je klíčová a jak se říká, štěstí přeje připraveným. Na každou prohlídku máme připravenou brožuru se všemi informacemi o nemovitosti. I takové maličkosti dokážou rozhodnout o tom, zda je obchod úspěšný či ne. A u nás v Häusl&amp;Home reality dbáme na detail, protože detail tvoří celek! Také připravujeme 3D půdorys, aby jste měli tu nejlepší představu o nemovitosti.\n\n\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/1b6d98309552404c85aa0d3a316292b4_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/1b6d98309552404c85aa0d3a316292b4_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/1b6d98309552404c85aa0d3a316292b4_s=660x_.png 660w, https://cdn.swbpg.com/t/37752/1b6d98309552404c85aa0d3a316292b4_s=860x_.png 860w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Organizace a plánování prohlídek</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Telefon může drnčet celý den, vy jste v práci nebo máte omezené časové možnosti. S námi vám tyto starosti odpadnou. Zajistíme, že nezmeškáte žádnou příležitost a můžete se soustředit na svůj život. Opět vám tím šetříme nejcennější komoditu – váš čas.\n\n\n\n<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --center pb--16 pl--12 pr--12 pt--16" style={{"backgroundColor":"rgba(245,248,253,1)"}} anim={"2"}>
              
              <Image alt={"Ikonka"} src={"https://cdn.swbpg.com/t/37752/44d4e73968b545daab2dbe07924370a0_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/37752/44d4e73968b545daab2dbe07924370a0_s=350x_.png 350w, https://cdn.swbpg.com/t/37752/44d4e73968b545daab2dbe07924370a0_s=660x_.png 660w"} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box fs--22 w--500" style={{"marginTop":7}} content={"<a href=\"/chci-hypoteku\">Obchodní jednání se zájemci</a>"}>
              </Subtitle>

              <Text className="text-box text-box--justify fs--14 w--400 lh--16" style={{"marginTop":12}} content={"Máme více než 10 let zkušeností v oblasti vyjednávání a obchodu. Díky těmto zkušenostem dokážeme vyhodnotit různé situace tak, aby byl pro vás prodej co nejvýhodnější.\n\n\n\n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--1 flex--center" anim={""} animS={"3"} style={{"marginTop":70}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --center" style={{"maxWidth":"","paddingBottom":5,"paddingTop":0}} anim={"2"}>
              
              <Text className="text-box fs--16" content={"Zaujaly vás mé služby, nebo máte nějaké otázky? Rád vám odpovím!\n\n\n\n\n\n\n"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape4 fs--16" style={{"marginTop":13}} content={"Kontaktujte mě"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":89,"paddingBottom":0}} name={"prefo"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image style={{"maxWidth":269}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={""} alt={"Logo"} src={"https://cdn.swbpg.com/o/23336/b3cf4f59a6ab4e17a17e6f6b21f73238.svg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Title className="title-box lh--14" style={{"marginBottom":19}} content={"Jsme Häusl&amp;Home Reality<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":442,"marginTop":0}} content={"pomáháme Vám najít nový domov a provést Vás cestou k nové životní etapě. Upřímnost a odborné jednání jsou pro nás na prvním místě. Chceme být Vašimi partnery během celého procesu, aby pro Vás byl co nejjednodušší a bezstarostný."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"e9jd34g191i"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"f0lqvhbqnwn"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box w--500" style={{"maxWidth":""}} content={"Häusl&amp;Home Reality"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={" Ke kapslovně 2855/1 Praha 3 130 00\n"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"tel:+420 724 808 718\" style=\"color: var(--color-blend--05);\">+420 724 808 718\n</a>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":8}} content={"<a href=\"mailto:miroslav.hausl@hauslahome.cz\" style=\"color: var(--color-blend--05);\">miroslav.hausl@hauslahome.cz\n</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" content={"<a href=\"/sluzby\">Služby</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/nemovistinaprodej\">Nemovitosti</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":3}} content={"<a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}